var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.privacyPolicyPageContent)?_c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[(!_vm.spinnerActive)?_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('h2',[_vm._v("Privacy Policy page")])]),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Page title (shown in browser tab)"},model:{value:(_vm.privacyPolicyPageContent.privacy_policy_tab_title.value),callback:function ($$v) {_vm.$set(_vm.privacyPolicyPageContent.privacy_policy_tab_title, "value", $$v)},expression:"privacyPolicyPageContent.privacy_policy_tab_title.value"}},'v-text-field',attrs,false),on))]}}],null,false,1637879115)},[_c('span',[_vm._v("SEO Page title - This is the title of the tab of the page and also shows up "),_c('br'),_vm._v("in search engines as the main title for the page. "),_c('br'),_vm._v("Keep the title short (max. 60 characters) and "),_c('br'),_vm._v("utilise keywords from the page. ")])])],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"label":"SEO - Meta description (this description will appear on google search results)"},model:{value:(_vm.privacyPolicyPageContent.privacy_policy_og_meta_desc.value),callback:function ($$v) {_vm.$set(_vm.privacyPolicyPageContent.privacy_policy_og_meta_desc, "value", $$v)},expression:"privacyPolicyPageContent.privacy_policy_og_meta_desc.value"}},'v-textarea',attrs,false),on))]}}],null,false,1654891499)},[_c('span',[_vm._v("The meta description is shown in search engines and "),_c('br'),_vm._v("is used to describe the contents of the page. "),_c('br'),_vm._v("Keep it between 50-160 characters and try to "),_c('br'),_vm._v("imagine what a user would search for in order "),_c('br'),_vm._v("to find the contents of the page. ")])])],1),_c('v-row',[_c('div',{staticStyle:{"height":"60vh","width":"100%"}},[(_vm.editorReady)?_c('editor',{attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
            images_upload_url: (_vm.baseURL + "/api/image-upload"),
            height: '100%',
            menubar: false,
            document_base_url: (_vm.baseURL + "/"),
            relative_urls: false,
            remove_script_host: true,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount' ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help \
              media image',
          }},model:{value:(_vm.privacyPolicyPageContent.privacy_policy_desc.value),callback:function ($$v) {_vm.$set(_vm.privacyPolicyPageContent.privacy_policy_desc, "value", $$v)},expression:"privacyPolicyPageContent.privacy_policy_desc.value"}}):_vm._e()],1)]),_c('div',{staticClass:"mt-8 save-button-wrapper"},[_c('v-btn',{attrs:{"color":"primary","elevation":"2","type":"submit","disabled":_vm.spinnerActive}},[_vm._v("Save")])],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }