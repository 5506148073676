<template>
  <div class="page-wrapper" v-if="privacyPolicyPageContent">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-form
        ref="form"
        @submit.prevent="save()"
        v-model="valid"
        action="/"
        v-if="!spinnerActive"
      >
        <v-row><h2>Privacy Policy page</h2></v-row>
        <v-row>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="privacyPolicyPageContent.privacy_policy_tab_title.value"
                label="SEO - Page title (shown in browser tab)"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <span>SEO Page title - This is the title of the tab of the page and also shows up
              <br>in search engines as the main title for the page.
              <br>Keep the title short (max. 60 characters) and
              <br>utilise keywords from the page.
            </span>
          </v-tooltip>
        </v-row>
        <v-row>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-textarea
              v-model="privacyPolicyPageContent.privacy_policy_og_meta_desc.value"
              v-bind="attrs"
              v-on="on"
              label="SEO - Meta description (this description will appear on google search results)"
            ></v-textarea>
            </template>
            <span>The meta description is shown in search engines and
              <br>is used to describe the contents of the page.
              <br>Keep it between 50-160 characters and try to
              <br>imagine what a user would search for in order
              <br>to find the contents of the page.
            </span>
          </v-tooltip>
        </v-row>
        <v-row>
        <div style="height: 60vh; width: 100%;">
          <editor
            v-if="editorReady"
            v-model="privacyPolicyPageContent.privacy_policy_desc.value"
            api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
            :disabled="false"
            initial-value=""
            :init="{
              images_upload_url: `${baseURL}/api/image-upload`,
              height: '100%',
              menubar: false,
              document_base_url: `${baseURL}/`,
              relative_urls: false,
              remove_script_host: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help \
                media image',
            }"
          />
        </div>
      </v-row>
        <div class="mt-8 save-button-wrapper">
          <v-btn color="primary" elevation="2" type="submit" :disabled="spinnerActive">Save</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { fetchPrivacyPolicyPage, updatePrivacyPolicyPage } from "@/services";
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "PrivacyPolicyPage",
  components: {
    TheSidebar,
  },
  data () {
    return {
      privacyPolicyPageContent: null,
      spinnerActive: false,
      valid: false,
      editorReady: true,
      baseURL: ''
    }
  },
  mounted() {
    this.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin
    this.getPrivacyPolicyPage()
  },
  methods: {
    async getPrivacyPolicyPage() {
      await fetchPrivacyPolicyPage().then(response => {
        this.privacyPolicyPageContent = response.data.data
      }).catch(error => {
        console.log('error', error)
      })
    },
    async save() {
      let data = {
        privacy_policy_desc: this.privacyPolicyPageContent.privacy_policy_desc.value,
        privacy_policy_tab_title: this.privacyPolicyPageContent.privacy_policy_tab_title.value,
        privacy_policy_og_meta_desc: this.privacyPolicyPageContent.privacy_policy_og_meta_desc.value
      }
      await updatePrivacyPolicyPage(data).then(response => {
        console.log('response', response)
      })
    }
  }
}
</script>
<style scoped>
.save-button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-content-wrapper {
  padding: 20px
}
</style>